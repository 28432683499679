import { BaseUrl } from "./ApiUrl";

const fetchProfileData = async () => {
    const Id = localStorage.getItem("usr_id")
    try {
      const response = await fetch(`${BaseUrl}/profile/${Id}`); 
      if (!response.ok) {
        throw new Error('Failed to fetch profile data');
      }
    
      const data = await response.json();
      return data.data;
    } catch (error) {
      console.error(error);
      // Handle error (e.g., show error message to the user)
    }
  };

 export const getAllUsers = async ()=>{
    try {
      const response = await fetch(`${BaseUrl}/all_user_list`); 
      if (!response.ok) {
        throw new Error('Failed to fetch all users data');
      }
    
      const data = await response.json();
      return data.data;
    } catch (error) {
      console.error(error,"while fetch all users data");
      // Handle error (e.g., show error message to the user)
    }
  }

 export const postShare = async (id)=>{
    try {
      const response = await fetch(`${BaseUrl}/userpost/share/${id}`); 
      if (!response.ok) {
        throw new Error('Failed to share post');
      }
    
      const data = await response.json();
      return data.data;
    } catch (error) {
      console.error(error,"while share post");
    }
  }

  export default fetchProfileData;