import axios from "axios"
import { BaseUrl } from "./ApiUrl";



export const AddPostApi = (postData)=>{


    let data = axios.post(`${BaseUrl}/userpost/add`,{...postData})
    .then((res)=>{
        return res
    }).catch((error)=>{
        console.log("add post api error" , error);
    })

    if(data){
        return data
    }
    
}

export const updatePostApi = (postData)=>{


    let data = axios.post(`${BaseUrl}/userpost/edit`,{...postData})
    .then((res)=>{
        return res
    }).catch((error)=>{
        console.log("update post api error" , error);
    })

    if(data){
        return data
    }
    
}


export const addPostData = ()=>{

    var id = localStorage.getItem("usr_id")

    //  let data= axios.get(`${BaseUrl}/userpost/show_by_user/${id}`)
     let data= axios.get(`${BaseUrl}/userpost/list`)
    .then((res)=>{
        return res
    })
    .catch((error)=>{
       
        console.log("get post api error" , error);
        
    })
    if(data){
        return data
    }
    
}
export const addprofiledata = ()=>{

    var id = localStorage.getItem("usr_id")

      let data= axios.get(`${BaseUrl}/userpost/show_by_user/${id}`)
    //  let data= axios.get(`${BaseUrl}/userpost/list`)
    .then((res)=>{
        return res
    })
    .catch((error)=>{
       
        console.log("get post api error" , error);
        
    })
    if(data){
        return data
    }
    
}

export const commentData = (comment)=>{
    
 let data =  axios.post(`${BaseUrl}/userpost/comment/add`,{...comment})
.then((res)=>{
    return res
})
.catch((error)=>{
   
    console.log("comment post api error" , error);
})

if(data){
    return data
}

}

export const followUser = (userData)=>{

let data = axios.post(`${BaseUrl}/user/follow`,{...userData})
    .then((res)=>{
        return res
    }).catch((error)=>{
        console.log("follow user api error" , error);
    })

    if(data){
        return data
    }
    
}
export const unFollowUser = (userData)=>{

let data = axios.post(`${BaseUrl}/user/unFollow`,{...userData})
    .then((res)=>{
        return res
    }).catch((error)=>{
        console.log("unfollow user api error" , error);
    })

    if(data){
        return data
    }
    
}
export const followbackUser = (userData)=>{

let data = axios.post(`${BaseUrl}/user/followBack`,{...userData})
    .then((res)=>{
        return res
    }).catch((error)=>{
        console.log("followback user api error" , error);
    })

    if(data){
        return data
    }
    
}
export const updateImage = (image)=>{

let data = axios.post(`${BaseUrl}/profile`,{image})
    .then((res)=>{
        return res
    }).catch((error)=>{
        console.log("update image api error" , error);
    })

    if(data){
        return data
    }
    
}

export const likePost = (userData)=>{

let data = axios.post(`${BaseUrl}/userpost/like`,{...userData})
    .then((res)=>{
        return res
    }).catch((error)=>{
        console.log("post like api error" , error);
    })

    if(data){
        return data
    }
    
}